(function (sCDN, sCDNProject, sCDNWorkspace, sCDNVers, sCDNHash) {
    function setConsent(status) {
        if (status) {
            const vendors = {
                'matomo': status.accepted?.includes('analytics') || false,
                'cstm_mediacom': status.accepted?.includes('marketing') || false,
            };
            window.jentis.consent?.engine?.setNewVendorConsents(vendors);
        }
    }

    setConsent(window.consentManager);
    document.addEventListener('jentis.consent.engine.init', () => setConsent(window.consentManager));
    window.addEventListener('consent-updated', event => setConsent(event.detail));

    if (
        window.localStorage !== null &&
        typeof window.localStorage === "object" &&
        typeof window.localStorage.getItem === "function" &&
        window.sessionStorage !== null &&
        typeof window.sessionStorage === "object" &&
        typeof window.sessionStorage.getItem === "function"
    ) {
        sCDNVers =
            window.sessionStorage.getItem("jts_preview_version") ||
            window.localStorage.getItem("jts_preview_version") ||
            sCDNVers;
    }
    window.jentis = window.jentis || {};
    window.jentis.config = window.jentis.config || {};
    window.jentis.config.frontend = window.jentis.config.frontend || {};
    window.jentis.config.frontend.cdnhost =
        sCDN + "/get/" + sCDNWorkspace + "/web/" + sCDNVers + "/";
    window.jentis.config.frontend.vers = sCDNVers;
    window.jentis.config.frontend.env = sCDNWorkspace;
    window.jentis.config.frontend.project = sCDNProject;
    window._jts = window._jts || [];
    var f = document.getElementsByTagName("script")[0];
    var j = document.createElement("script");
    j.async = true;
    j.src = window.jentis.config.frontend.cdnhost + sCDNHash + '.js';
    f.parentNode.insertBefore(j, f);
})(
    document.currentScript.dataset.cdn,
    document.currentScript.dataset.project,
    document.currentScript.dataset.workspace,
    document.currentScript.dataset.version,
    document.currentScript.dataset.hash
);

_jts.push({track: "pageview"});
_jts.push({track: "submit"});
